@import url("https://fonts.googleapis.com/css2?family=KoHo&family=Lobster+Two:ital@1&family=Noto+Sans+JP&display=block");

html,
body {
  width: 100%;
  height: 100%;

  margin: 0px;
  padding: 0px;
  overflow-x: hidden;

  scroll-behavior: smooth;
}

#root {
  width: 100%;
}

h1 {
  text-decoration: 0;
  margin: 0px;
  padding: 0px;
}

.MainPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: min-content; */
  /* min-width: min-content; */
}

#logo {
  width: 250px;
  height: 250px;
  margin-top: 50px;
  filter: brightness(120%);
  border-radius: 50%;
}

#backdrop {
  position: absolute;
  /* height: 600px; */
  left: 0px;
  top: 0px;
  z-index: -1;
  object-fit: none;
  filter: brightness(90%);
}

#banner-message {
  font-size: 30px;
  font-weight: 250;
  color: #fff;
  font-family: "Lobster Two";
  background-color: rgba(0, 0, 0, 0.686);
  text-align: center;
  padding: 10px;
  border-radius: 10px;

  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
}

#TopBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #8fa5b1;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */

  background-image: url("./assets/tree-backdrop-ws.png");
  object-fit: cover;
  width: 100%;
  box-shadow: 0 0px 1px var(--purple-color), 0 0px 5px var(--purple-color);
}

#info-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
}

.info-card {
  /* background: var(--light-pink-color); */
  background: var(--bright-pink);

  width: 90%;
  max-width: 1000px;
  border-radius: 3px;
  box-shadow: 0 0px 1px var(--purple-color), 0 0px 5px var(--purple-color);
  font-size: 22px;
  font-weight: 400;
  font-family: "Koho";
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 400px) {
  .info-card {
    width: 90vw;
  }
}

.info-card:last-child {
  margin-bottom: 150px;
}

/* #D0FFA4 : Green color */
:root {
  --purple-color: #ac85a4;
  --light-pink-color: #e4c2de;
  --logo-green-color: #d0ffa4;
  --bright-pink: #ffeaff;
  --gray-color: #4d4d4d;
  --very-bright-pink: rgb(255, 0, 234);
}

.card {
  font-family: "Noto Sans JP";
  font-weight: 100;
  font-size: 30px;
  background-color: var(--gray-color);
  padding: 30px;
  color: #ffffff;
  box-shadow: 0 0px 1px var(--purple-color), 0 0px 10px var(--purple-color);
}

.card-body {
  color: rgb(110, 31, 93);
  white-space: pre-wrap;
  padding: 20px;
}

.card-caption {
  color: gray;
  margin: 5px;
  margin-top: 30px;
  align-self: flex-end;
  width: 80%;
  text-align: end;
  font-style: italic;
  font-size: 20px;
  padding: 10px;
}

#email {
  color: rgb(159, 65, 236);
  word-wrap: break-word;
}

#phone-number {
  color: rgb(159, 65, 236);
}

.hijama-link {
  font-weight: bold;
  color: var(--gray-color);
}

.hijama-link:hover {
  color: var(--very-bright-pink);
}

#navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Noto Sans JP";
  font-weight: 100;
}

.navbtn {
  margin: 20px;
  margin-bottom: 0px;
  background-color: #ac85a4;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--purple-color);
  user-select: none;
  width: 120px;
  height: 40px;
  transition: 0.2s;
  -webkit-tap-highlight-color: transparent;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navbtn:hover {
  cursor: pointer;
  background-color: #fcb0ee;
}

.navbtn:hover {
  cursor: pointer;
  background-color: #fcb0ee;
}

.navbtn:active {
  background-image: linear-gradient(
    15deg,
    rgba(228, 194, 222, 1) 0%,
    rgb(235, 122, 214) 100%
  );
  /* animation-name: shineabit; */
  /* animation-duration: 1s; */
}

@keyframes shineabit {
  0% {
    background-image: linear-gradient(
      15deg,
      rgba(228, 194, 222, 1) 0%,
      rgb(235, 122, 214) 100%
    );
  }

  100% {
    background-image: linear-gradient(
      15deg,
      rgb(235, 122, 214) 0%,
      rgba(228, 194, 222, 1) 100%
    );
  }
}

a {
  text-decoration: none;
}

#email,
#phone-number {
  text-decoration: none;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word !important; /* Internet Explorer 5.5+ */
}

.linked-url {
  position: relative;
  top: 20px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  color: var(--gray-color);
}

.linked-url:hover {
  color: var(--very-bright-pink);
}

.hadith {
  display: block;
  background-color: #ffeaff;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 35px;
  margin-top: -20px;
}

#odd-section-text {
  background-color: #fcd4f4;
}

.testimonial {
  display: block;
  background-color: #ffeaff;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 35px;
  margin-top: -20px;
  font-style: italic;
  font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 50px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 640px) {
  .testimonial {
    padding: 30px;
  }
}

.testimonial:last-child {
  padding-bottom: 10px;
}

.react-icons {
  padding-left: 20px;
  color: var(--purple-color);
  font-size: 30px;
}

.google-map {
  display: flex;
  justify-content: center;
  align-items: center;
}
